@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk {
    &-option-card {
        display: flex;
        flex-flow: column;
        position: relative;
        margin-bottom: 0;
        min-height: 175px;
        padding-top: 3px;
        padding-bottom: cssvar(spacing-2);
        padding-left: cssvar(spacing-5);

        &__inner {
            display: flex;
            column-gap: cssvar(spacing-2);
            padding-top: cssvar(spacing-1);
            margin-bottom: cssvar(spacing-2);
        }
        &__label-shift {
            position: absolute;
            right: cssvar(spacing-1);
            top: cssvar(spacing-1);
        }
        &--alt-label {
            min-height: 100%;
            justify-content: center;
            align-items: center;
            padding-top: var(--hcr-spacing-2);
            padding-bottom: var(--hcr-spacing-2);
        }

        &__stop {
            display: inline-block;
            width: 245px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &__bottom {
            display: flex;
            justify-content: space-between;
        }
        &--active {
            border-color: cssvar(success-color);
            position: relative;
            box-shadow: 0 0 0 1px cssvar(success-color),
                0 0 600px -300px cssvar(success-color) inset;
        }
        &--danger {
            border-color: cssvar(danger-color);
        }

        .duration {
            font-size: cssvar(font-size-small);
            color: cssvar(content-secondary-color);
            min-width: 78px; // to fit long duration
            display: inline-block;
            text-align: center;
        }
    }
    &-option-ic {
        &__tag {
            font-size: cssvar(font-size-small);
        }
        &__tooltip {
            max-width: none;
            width: auto;
            white-space: nowrap;
        }
        &__connection {
            margin-bottom: 0;

            &:last-of-type > div {
                margin-bottom: 0;
            }

            table {
                margin-bottom: 0;
            }
        }
    }
    &-options-slot {
        display: flex;
        gap: cssvar(spacing-2);

        &--full-width {
            flex-flow: column;
            text-align: center;
        }

        &__options {
            position: relative;
            flex-grow: 4;
            min-width: calc(100% / 3 - cssvar(spacing-2));
        }
        &__alternatives {
            flex-grow: 4;
            min-width: calc(100% / 3 - cssvar(spacing-2));
        }
    }
}
