@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.suggestion-trip-card {
    position: relative;
    min-width: 552px;
    width: auto;
    padding: cssvar(spacing-4) cssvar(spacing-5) cssvar(spacing-3)
        cssvar(spacing-5);
    border: 1px solid cssvar(line-primary-color);
    border-radius: 6px;
    margin-bottom: 6px;

    &__main {
        display: flex;
    }

    &__left {
        width: calc(100% - 114px);
        margin-right: cssvar(spacing-2);
    }

    &__info {
        display: flex;
        justify-content: space-between;
        position: relative;

        &-from-to {
            position: relative;
            width: 230px;
            z-index: 2;
            color: cssvar(grayscale-90-color);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            .trip-card-time {
                color: cssvar(grayscale-100-color);
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                background: white;
                padding: 0 cssvar(spacing-1) 0 0;
            }
        }
        &-name {
            color: cssvar(grayscale-100-color);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;

            [role='tooltip'] {
                width: 144px;

                & > div {
                    text-align: center;
                }
            }
        }

        .trip-card-duration {
            position: absolute;
            z-index: 2;
            width: 100px;
            left: calc(50% - 100px); // center minus width
            background: white;
            padding: 0 cssvar(spacing-2);
            color: cssvar(grayscale-90-color);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }

        &:after {
            content: '';
            position: absolute;
            width: 65%; // based on the design
            top: 12px;
            transform: translateY(-50%);
            display: block;
            border: 1px solid var(--hcr-grayscale-30-color);
            z-index: 0;
        }
    }

    &__durration-diff {
        position: absolute;
        right: -8px;
        top: -2px;
    }

    &__type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0 0 0;

        &-inner {
            display: flex;
            align-items: center;
        }

        &-icons {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            margin-right: 12px;
            border-radius: 50%;
            border: 1px solid cssvar(grayscale-30-color);
            z-index: 0;
        }

        .interconnection-arrow {
            display: block;
            position: absolute;
            top: 50%;
            left: 35px;
            transform: translate(-50%, -50%);
            z-index: 1;
            width: 12px;
            height: 12px;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='5.5' fill='white' stroke='%23E1E1E1'/%3E%3Cpath d='M5.08523 8.35602C5.05825 8.32975 5.03682 8.29834 5.02218 8.26364C5.00754 8.22895 5 8.19168 5 8.15402C5 8.11637 5.00754 8.0791 5.02218 8.0444C5.03682 8.00971 5.05825 7.9783 5.08523 7.95202L7.24922 5.78802C7.26036 5.78052 7.26948 5.77039 7.27579 5.75854C7.2821 5.74668 7.28539 5.73345 7.28539 5.72002C7.28539 5.70659 7.2821 5.69337 7.27579 5.68151C7.26948 5.66966 7.26036 5.65953 7.24922 5.65202L5.08523 3.48802C5.03165 3.43432 5.00161 3.36153 5.0017 3.28567C5.0018 3.20981 5.03202 3.1371 5.08573 3.08352C5.13943 3.02995 5.21222 2.99991 5.28808 3C5.36394 3.00009 5.43665 3.03032 5.49022 3.08402L7.65322 5.26502C7.7748 5.38573 7.84503 5.54875 7.84922 5.72002C7.84503 5.8913 7.7748 6.05432 7.65322 6.17502L5.49022 8.35602C5.46393 8.38315 5.43246 8.40472 5.39768 8.41945C5.36289 8.43417 5.3255 8.44176 5.28773 8.44176C5.24995 8.44176 5.21256 8.43417 5.17777 8.41945C5.14299 8.40472 5.11152 8.38315 5.08523 8.35602V8.35602Z' fill='%23C8C8C8'/%3E%3C/svg%3E%0A");
        }
    }

    &__interconnections {
        position: relative;
        display: flex;

        .suggestion-trip-card__type-icons {
            &:last-of-type {
                margin-left: -12px;
            }
        }
    }

    &__time-diff {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-flow: column;
        margin: 0 0 -2px 0;
    }

    .dropdown-icon {
        margin-right: cssvar(spacing-2);

        .hci-icon {
            width: 14px !important;
            height: 14px !important;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 114px;

        button {
            min-width: 100%;
        }
    }

    &__capacity {
        span {
            font-size: 16px;
            line-height: 24px;
            color: cssvar(content-secondary-color);
            margin-right: 13px;

            span {
                margin-right: 3px;
            }

            &.danger {
                color: cssvar(danger-color);

                .hci-icon {
                    fill: cssvar(danger-color) !important;
                }
            }
        }
    }

    &--original {
        background-color: cssvar(grayscale-10-color);
        align-items: center;

        .trip-card-time,
        .trip-card-duration {
            background-color: cssvar(grayscale-10-color);
        }
    }

    .different-station {
        margin: -7px 4px 0 0;

        svg {
            width: 24px;
            height: 24px;
            fill: cssvar(button-primary-color);
        }
    }

    &__ics {
        border-top: 1px solid #c8c8c8;
        padding-top: cssvar(spacing-2);
        margin-top: cssvar(spacing-3);

        & > div {
            margin: 0;

            & > [class^='hcr-connection'] {
                margin-bottom: 0;
            }

            table {
                margin: 0;
            }
        }

        div:last-of-type {
            & > div {
                margin: 0;
            }
        }
    }

    .different-station-tooltip {
        max-width: 100px; // do not cur the message
    }
}
