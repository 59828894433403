@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk-simple-connection {
    margin-bottom: cssvar(spacing-2);
    line-height: calc(cssvar(line-height-primary) * 0.8);
    border-collapse: collapse;
    caption {
        margin-bottom: cssvar(spacing-1);
    }
    tr {
        td {
            min-width: 1px;
            vertical-align: middle;
            &:last-child {
                width: 100%;
            }
        }
    }

    &__entry--left {
        position: relative;
        min-height: 100%;
        margin-right: 0;
        text-align: right;
    }
    &__entry--right {
        position: relative;
        min-height: 100%;
        margin-left: calc(#{cssvar(spacing-4)} + 5px);
        &:before {
            content: '';
            position: absolute;
            display: none;
            left: calc(-1 * #{cssvar(spacing-4)} - 5px);
            width: 9px;
            height: 9px;
            margin-top: calc(-1 * 5px);
            border: 1px solid cssvar(icon-primary-color);
            border-radius: 50%;
            z-index: 2;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: calc(-1 * #{cssvar(spacing-1)});
            bottom: calc(-1 * (#{cssvar(line-height-primary)} * 0.8));
            left: calc(-1 * #{cssvar(spacing-4)});
            width: 1px;
            background: cssvar(icon-primary-color);
            z-index: 1;
        }
    }

    tr:first-child &__entry--left,
    tr:last-child &__entry--left {
        font-weight: cssvar(font-weight-bold);
        margin-right: calc(#{cssvar(spacing-4)} - 7px);
    }

    tr:first-child &__entry--right {
        &:before {
            display: inline-block;
            top: calc(50% - 4px);
            background-color: cssvar(bg-primary-color);
        }
        &:after {
            top: cssvar(spacing-2);
        }
    }

    tr:last-child &__entry--right {
        &:before {
            display: inline-block;
            top: calc(50% + 2px);
            background-color: cssvar(icon-primary-color);
        }
        &:after {
            top: calc(-1 * #{cssvar(line-height-primary)} / 2);
            bottom: 50%;
        }
    }
}
